import React, { useState } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

// import Text from './Text';
import Plus from '../../static/images/plus.svg';

const Wrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid lightgray;
  padding: 2rem 0;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding: 3.5rem 0;
  }

  &:first-child {
    padding: 0 0 2rem;
    @media (${({ theme }) => theme.respondTo.desktop}) {
      padding: 0 0 3.5rem;
    }
  }
`;

const Title = styled.h3`
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 6.5rem;
  font-size: 2rem;
  cursor: pointer;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    padding-right: 3.5rem;
    font-size: 1.8rem;
  }

  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transition: all 0.25s;
    transform: ${({ isOpen }) =>
      isOpen ? 'translateY(-50%) rotate(45deg)' : 'translateY(-50%)'};
  }
`;

const StyledText = styled.div`
  padding: ${({ alt }) => (alt ? '2rem 0 0 0;' : '2rem 0 0')};
  font-size: 1.5rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding: ${({ alt }) => (alt ? '3.5rem 0 0 22rem;' : '3.5rem 0 0')};
    font-size: 1.8rem;
  }

  a {
    text-decoration: underline;
  }
`;

const Accordion = ({ title, text }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Wrapper>
      <Title isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <span>{title}</span>
        <SVG src={Plus} />
      </Title>
      {isOpen && (
        <StyledText>
          <p dangerouslySetInnerHTML={{ __html: text }} />
        </StyledText>
      )}
    </Wrapper>
  );
};

export default Accordion;
