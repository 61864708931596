import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Container from '../components/Container';
import Title from '../components/Title';
import Arrow from '../../static/images/arrow-left.svg';
import Accordions from '../components/Accordions';

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamilyHeading};
  text-transform: uppercase;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    font-size: 1.8rem;
  }

  svg {
    height: auto;
    margin-right: 1.5rem;
    width: 2.5rem;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      margin-right: 2rem;
      width: 3rem;
    }
  }
`;

const StyledTitle = styled(Title)`
  margin: 4rem 0 1.5rem;
  font-size: 4rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin: 8rem 0 1.5rem;
    font-size: 7.75rem;
  }
`;

const Wrapper = styled.div`
  align-self: flex-start;
  margin-top: 20vh;
  width: 100%;
`;

const IndexPage = () => {
  const items = [
    {
      title: 'Is een webshop wel geschikt voor mijn bedrijf?',
      text:
        'Zie het zo: bijna alles wat je normaliter in je winkel verkoopt, kan je ook hier verkopen. Het verschil is dat je alleen niet ziet wie er allemaal in je winkel rondneust. Als een klant eenmaal een bestelling heeft geplaatst, ontvang jij een e-mail met daarin zowel de klantgegevens als de bestelling zelf. De verdere afhandeling (betaling & bezorging/afhalen) kun je zelf met de klant afstemmen. <br><br> <b>Tip:</b> Verstuur een online betaalverzoek! Zo heb jij als ondernemer direct het geld, en kan de bestelling contactloos worden afgeleverd bij de klant. Win win! ',
    },
    {
      title: 'Binnen 1 dag online? Hoe kan dat?',
      text:
        "Het enige dat wij hoeven te doen is jouw subdomein activeren. Dus afhankelijk van hoe snel jij de content (teksten, producten & foto's) klaar hebt, kan je webshop vrijwel direct online.",
    },
    {
      title: 'Ik heb geen/weinig kennis van online verkopen, maakt dat uit?',
      text:
        'Nee. Het makkelijke van deze webshop is namelijk dat je, nadat je een bestelling hebt ontvangen, zelf contact op kunt nemen met de klant. Via telefoon of e-mail, wat je zelf het prettigst vindt. Geen online skills voor nodig.',
    },
    {
      title: "Hoe lever ik mijn teksten, producten & foto's aan?",
      text:
        "Na het aanvragen van een webshop ontvang je van ons een document (Google Sheets). In dit document geef je aan welke teksten en producten je in je webshop wilt hebben. Wij sturen ook een voorbeeld document mee zodat je kunt zien hoe het werkt. De foto's upload je in een mapje (Google Drive), ook hiervan ontvang je een link. <br><br>Mocht je er niet uitkomen, dan kun je ons altijd bereiken per e-mail: <a href='mailto:hi@lokaly.shop'>hi@lokaly.shop</a>",
    },
    {
      title: 'Hoe kan het dat deze dienst gratis is?',
      text:
        'Simpel gezegd: voor ondernemers, door ondernemers. De huidige crisis raakt iedereen, maar economisch gezien zijn het vooral de kleine, lokale ondernemers die in de hoek zitten waar de klappen vallen. Via deze dienst hopen wij op een laagdrempelige manier mede-ondernemers een handje te kunnen helpen met het continueren van hun business. En zoals Rutte ons toesprak: "Let een beetje op elkaar". Bij deze.',
    },
    {
      title:
        'Hoe kan ik jullie steunen in het doorontwikkelen van lokaly.shop?',
      text:
        'lokaly.shop draait volledig op donaties. Dus als je blij bent met onze dienst en dit project wilt steunen, dat kan! Doneren is mogelijk via <a target="_blank" rel="noopener nofollow" href="https://tikkie.me/pay/uhopndeuuclqkh97d0op"> deze Tikkie link </a>',
    },
    {
      title: 'Wat kan ik allemaal zelf beheren in mijn webshop?',
      text:
        "In het document dat je ontvangt na registratie kun je aangeven welke hoofdkleur je wilt gebruiken (paars in de voorbeeldwebsite). Daarnaast is het mogelijk om alle teksten, producten en foto's te wijzigen. Wat je niet kunt aanpassen is de structuur van de webshop: volgorde, plaatsing en formaten van elementen. Ook de velden in het formulier staan vast.",
    },
    {
      title: 'Ik wil later een tekst/product/foto aanpassen, hoe doe ik dat?',
      text:
        "Via het opgestuurde Google Sheets document kun je altijd zelf je teksten, producten en/of foto's aanpassen. De wijzigingen zijn direct zichtbaar in je webshop.",
    },
  ];

  const seo = {
    title: 'FAQ — lokaly.shop',
    description: 'De meestgestelde vragen op een rijtje.',
  };

  return (
    <Layout seo={seo}>
      <Wrapper>
        <Container>
          <div>
            <StyledLink to="/">
              <SVG src={Arrow} /> Terug naar homepage
            </StyledLink>
            <StyledTitle as="h1" primaryColor>
              FAQ
            </StyledTitle>
            <Title big>De meestgestelde vragen op een rijtje.</Title>
            <Accordions items={items} />
          </div>
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;
