import React from 'react';
import styled from 'styled-components';
import Accordion from './Accordion';

const Wrapper = styled.div`
  width: 100%;
  max-width: 90rem;
  margin: 10rem 0;
`;

const Accordions = ({ items }) => {
  return (
    <Wrapper>
      {items.map(item => (
        <Accordion
          key={item.title + item.text}
          title={item.title}
          text={item.text}
        />
      ))}
    </Wrapper>
  );
};

export default Accordions;
